import React from 'react'
import { Select } from 'antd'
import MyIcon from '../general/icon'

const MyDegreeSelect = ({ 
  id,
  required = false,
  label = '',
  value = '',
  onChange,
  errorMsg,
  disabled = false,
  notFoundContent = '暂无数据',
  styles
}) => {
  let items = [
    {
      value: 1,
      label: '学士学位'
    },
    {
      value: 2,
      label: '硕士学位'
    },
    {
      value: 3,
      label: '博士学位'
    }
  ]

  const noData = (
    <div className='select-noData'>
      <span style={{ fontSize: '50px', color: '#aaa' }}><MyIcon name='kong' /></span>
      <p>{notFoundContent}</p>
    </div>
  )

  return (
    <div className='select-container'>
      <label>
        {label}
        {required && <span style={{ color: '#ae2a19' }}> *</span>}
      </label>

      <Select
        disabled={disabled}
        placeholder="选择一个学位要求"
        value={value}
        onChange={(value) => onChange(prevValues => ({ ...prevValues, [id]: value }))}
        notFoundContent={noData}
        options={items}
        style={{ width: '360px', height: '40px', marginBottom: '5px', ...styles }}
      />

      {errorMsg && (
        <div className="form-errormsg">
          <span><MyIcon name='jinggao' /></span> 
          <p>{errorMsg}</p>
        </div>
      )}
    </div>  
  )
}

export default MyDegreeSelect
