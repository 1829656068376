import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Pagination, message, Tooltip } from 'antd'
import MyIcon from '../../../components/general/icon'
import Facebodyribbon from './ribbon'
import ImportFacebodyStudent from './import'
import ExportFacebodyStudent from './export'
import DownloadFacebodyReport from './download'
import DeleteFacebodyStudent from './delete'
import FilterFacebodyStudent from './filter'
import FacebodyStudentDetail from './detail'

export default function FacebodyStudent() {
  const merchantOid = localStorage.getItem('merchant_oid')
  const [currentPage, setCurrentPage] = useState(1)
  const [studentData, setStudentData] = useState({})
  const [propsData, setPropsData] = useState({})
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isImportVisible, setIsImportVisible] = useState(false)
  const [isExportVisible, setIsExportVisible] = useState(false)
  const [isDownloadVisible, setIsDownloadVisible] = useState(false)
  const [isDetailVisible, setIsDetailVisible] = useState(false)

  const initialConditions = {
    category: '',
    college: '',
    major: '',
    status: 0,
    ethnicity: '',
    photo: 0,
    keywords: ''
  }
  const [filterValue, setFilterValue] = useState(initialConditions)

  const getStudentData = () => {
    axios.post('/p/facebody/student/list/get', { 
      merchant_oid: merchantOid,
      facebody_category_oid: filterValue.category,
      college: filterValue.college,
      major: filterValue.major,
      ethnicity: filterValue.ethnicity,
      is_photo: filterValue.photo,
      status: filterValue.status,
      keywords: filterValue.keywords,
      page: currentPage, 
      per_page: 20
    })
      .then(function (response) {
        if (response.errcode === 0) {
          setStudentData({
            'total_page': response.data.total_page,
            'current_page': response.data.current_page,
            'total': response.data.total,
            'students': response.data.students
          })
        }
      })
      .catch(function (error) {
        console.error('请求失败，错误信息为：', error)
      })
  }

  useEffect(() => {
    getStudentData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantOid, filterValue, currentPage])

  const downloadReport = () => {
    setIsDownloadVisible(true)
    setPropsData({ category: filterValue.category})
  }

  const deleteStudent = (oid, name) => {
    setIsDeleteVisible(true)
    setPropsData({ oid: oid, name: name})
  }

  const previewDetail = (oid) => {
    setIsDetailVisible(true)
    setPropsData({ oid: oid})
  }

  const previewReport = (verificationResult) => {
    if (verificationResult.status === 1) {
      message.error('无法预览，因为当前学生未参加核验！')
      return false
    }
    window.open(verificationResult.report, '_blank')
  }

  const getColor = (item) => {
    if (item.verification_result.status === 2) return 'green'
    if (item.verification_result.status === 3) return 'red'
    return 'black'
  }

  return (
    <div className="public-page">
      <div className="public-container">
        <div className="public-header">
          <div className="public-title">学生管理</div>
            <Facebodyribbon 
              filterValue={filterValue} 
              setFilterValue={setFilterValue}
              isImportVisible={() => { setIsImportVisible(true) }}
              isExportVisible={() => { setIsExportVisible(true) }}
              isDownloadVisible={downloadReport}
            />
        </div>

        <FilterFacebodyStudent 
          filterValue={filterValue} 
          setFilterValue={setFilterValue}
        />

        <div className='public-main-style2'>
          <table className='public-main-style2-item'>
            <thead>
              <tr className='public-main-style2-thead'>
                <th style={{textAlign: 'left', paddingLeft: '20px'}}>姓名</th>
                <th>身份证号</th>
                <th>学院</th>
                <th>专业</th>
                <th>所属分类</th>
                <th>核验状态</th>
                <th style={{width: '50px'}}></th>
              </tr>
            </thead>

            <tbody>
              {studentData.students && (studentData.students.length > 0 ? (studentData.students.map((item) => (
                <tr className='public-main-style2-tbody' key={item.oid} onClick={() => { previewDetail(item.oid) }}>
                  {item.photo ?  
                    <td style={{textAlign: 'left', padding: '5px 0 5px 20px', display: 'flex', alignItems: 'center'}}>
                      <img style={{height: '50px', marginRight: '10px'}} src={item.photo} alt="" />
                      {item.name}
                    </td> : <td style={{textAlign: 'left', paddingLeft: '20px'}}>{item.name}</td>
                  }
                  <td>{item.id_number}</td>
                  <td>{item.college}</td>
                  <td>{item.major}</td>
                  <td>{item.facebody_category}</td>
                  <td style={{ color: getColor(item) }}>{item.verification_result.message}</td>
                  <td style={{width: '100px'}}>
                    <div className='public-main-style2-operation-box'>
                      <Tooltip placement="bottom" color='#1A1B1C' title="核验报告">
                        <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); previewReport(item.verification_result)}}>
                          <MyIcon name='baogao' />
                        </div>
                      </Tooltip>
                      <Tooltip placement="bottom" color='#1A1B1C' title="删除">
                        <div className='public-main-style2-operation' onClick={(event) => {event.stopPropagation(); deleteStudent(item.oid, item.name)}}>
                          <MyIcon name='huishouzhan' />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))) : (
                <tr className='public-main-style2-tbodyEmpty'>
                  <td colSpan="7"><span>暂无数据</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <Pagination 
            current={currentPage}
            total={studentData.total}
            pageSize={20}
            onChange={(page) => setCurrentPage(page)}
            showTotal={(total) => `共${total}条`}
            showSizeChanger={false}
            hideOnSinglePage={true}
            showQuickJumper={true}
          />
        </div>
      </div>

      {isImportVisible && <ImportFacebodyStudent
        getStudentData={getStudentData}
        isOpen={isImportVisible}
        isCancel={() => { setIsImportVisible(false) }}
      />}

      {isExportVisible && <ExportFacebodyStudent
        filterValue={filterValue}
        isOpen={isExportVisible}
        isCancel={() => { setIsExportVisible(false) }}
      />}

      {isDownloadVisible && <DownloadFacebodyReport
        data={propsData}
        isOpen={isDownloadVisible}
        isCancel={() => { setIsDownloadVisible(false) }}
      />}

      {isDeleteVisible && <DeleteFacebodyStudent
        data={propsData}
        getStudentData={getStudentData}
        isOpen={isDeleteVisible}
        isCancel={() => { setIsDeleteVisible(false) }}
      />}

      {isDetailVisible && <FacebodyStudentDetail
        data={propsData}
        isOpen={isDetailVisible}
        isCancel={() => { setIsDetailVisible(false) }}
      />}
    </div>
  )
}
